@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

.small-col {
  width: 100px;
}

.md-col {
  max-width: 150px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.md-plus-col {
  max-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.main-screen-replacing {
  font-size: 3rem;
  font-weight: 600;
  color: white;
  opacity: 0.95;
  text-align: center;
  font-size: 1.25em;
  display: block;
  margin-bottom: 1rem;
}

.main-screen-replacing-text {
  font-size: 1.5rem;
  font-weight: 600;
  opacity: 0.95;
}

.main-bg {
  background: #f9f8fa url("./assets/images/bg_cover.png");
  background-repeat: no-repeat;
  background-position: 100% 0;
}

/* Tour component */
[data-tour-elem="badge"] {
  display: none;
}

[data-tour-elem="dot"]:is(.reactour__dot--is-active) {
  background-color: rgba(114, 101, 230) !important;
  color: rgba(114, 101, 230) !important;
}
